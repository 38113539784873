const downloadCSV = (params: { urls: IUrlIndexStatusItem[] }): void => {
    const result = [];

    result.push(`Url,Cache status,Cached domain name,Cached date`);

    result.push(
        params.urls
            .reduce((acc: string[], curr: any) => {
                acc.push(
                    `${curr.url},${
                        curr.data.statusCode === 200
                            ? curr.url === curr.data.cachedDomainName
                                ? 'Cached'
                                : 'Other cached'
                            : 'Not cached'
                    },${curr.data.cachedDomainName || '-'},${
                        (curr.data.cachedDate &&
                            curr.data.cachedDate.replace(',', '')) ||
                        '-'
                    }`
                );

                return acc;
            }, [])
            .join('\n')
    );

    const base64Content = Buffer.from(result.join('\n')).toString('base64');

    const a = document.createElement('a');
    a.setAttribute('download', 'data.csv');
    a.setAttribute(
        'href',
        `data:application/octet-stream;base64,${base64Content}`
    );
    a.click(); // omg this works
};

export default downloadCSV;
