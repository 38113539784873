import { MODULES } from '@/constants';
import { Store } from 'vuex';
import StoreModuleHelper from '../../../lib/helpers/store-helper';

export default class RequestQueueModule extends StoreModuleHelper {
    private getters = MODULES.URL_CACHE_CHECKER.STORE.GETTER;
    private mutations = MODULES.URL_CACHE_CHECKER.STORE.MUTATION;

    constructor(store: Store<IUploadedFileState>) {
        super(
            MODULES.URL_CACHE_CHECKER.STORE.NAMESPACE.CACHED_URL_REQUEST_QUEUE,
            store
        );
    }

    get currentProgress(): number {
        return this.getter(this.getters.CACHE_URL_STATUS_PROGRESS);
    }

    get requestQueue(): string[][] {
        return this.getter(this.getters.CACHE_URL_STATUS_REQUEST_QUEUE);
    }

    get thereArePendingUrlsToProcess(): boolean {
        return this.currentProgress >= 0 && this.currentProgress < 100;
    }

    setProgress(progress: number): void {
        this.commit(
            this.mutations.SET_FETCHING_CACHE_URL_STATUS_PROGRESS,
            progress
        );
    }

    cleanGroup(index: number): void {
        this.commit(this.mutations.CLEAN_URL_GROUP_FROM_REQUEST_QUEUE, index);
    }

    setRequestQueue(params: { urlList: string[]; batchSize: number }): void {
        const urls = [...params.urlList];
        const groupedUrls = [];

        while (urls.length > 0) {
            groupedUrls.push(urls.splice(0, params.batchSize));
        }

        this.commit(
            MODULES.URL_CACHE_CHECKER.STORE.MUTATION
                .SET_CACHE_URL_STATUS_REQUEST_QUEUE,
            groupedUrls
        );
    }

    setProgressAsFinished(): void {
        this.commit(this.mutations.SET_FETCHING_CACHE_URL_STATUS_PROGRESS, -1);
    }

    clearQueue(): void {
        this.commit(this.mutations.CLEAN_URL_CACHE_STATUS_REQUEST_QUEUE);
    }
}
