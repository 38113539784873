






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import RequestQueueModule from '../helpers/request-queue-module.helper';

@Component
export default class PreviewUrlsToCheck extends Vue {
    requestQueueStoreHelper: RequestQueueModule = new RequestQueueModule(
        this.$store
    );

    get urlList(): string[][] {
        return this.requestQueueStoreHelper.requestQueue;
    }
}
