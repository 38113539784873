













































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CachedUrlCollectionModule from '../helpers/cached-url-collection-module.helper';
import RequestQueueModule from '../helpers/request-queue-module.helper';

interface ResultItem {
    url: string;
    cachedDate: string;
    cachedDomainName: string;
    status: number;
}

@Component
export default class Results extends Vue {
    cachedUrlCollectionStoreHelper: CachedUrlCollectionModule = new CachedUrlCollectionModule(
        this.$store
    );

    requestQueueStoreHelper: RequestQueueModule = new RequestQueueModule(
        this.$store
    );

    search = '';

    get progress(): number {
        return this.requestQueueStoreHelper.currentProgress || 0;
    }

    get isProcessing(): boolean {
        return this.progress > -1 && this.progress < 100;
    }

    get headers(): any[] {
        return [
            {
                text: 'Url',
                value: 'url',
            },
            { text: 'Cached date', value: 'cachedDate' },
            { text: 'Cached domain name', value: 'cachedDomainName' },
            { text: 'Status', value: 'status' },
        ];
    }

    decorateStatus(
        item: ResultItem
    ): { color?: string; text: string; textColor?: string } {
        if (item.status === 200)
            return item.url !== item.cachedDomainName
                ? {
                      color: 'warning',
                      text: 'Cached with different url',
                  }
                : {
                      color: 'success',
                      text: 'Cached',
                  };

        if (item.status === 404)
            return {
                color: 'error',
                text: 'Not cached',
            };

        if (item.status === 500)
            return {
                color: 'transparent',
                textColor: '',
                text: 'Error checking url',
            };

        return {
            color: 'gray',
            text: 'Unknown',
        };
    }

    get items(): ResultItem[] {
        return this.cachedUrlCollectionStoreHelper.items.reduce(
            (acc: ResultItem[], curr: any) => {
                acc.push({
                    url: curr.url,
                    cachedDate: curr.data.cachedDate,
                    cachedDomainName: curr.data.cachedDomainName,
                    status: curr.data.statusCode,
                });

                return acc;
            },
            []
        );
    }
}
