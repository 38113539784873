import { MODULES } from '@/constants';
import { Store } from 'vuex';
import StoreModuleHelper from '../../../lib/helpers/store-helper';

export default class CachedUrlCollectionModule extends StoreModuleHelper {
    private getters = MODULES.URL_CACHE_CHECKER.STORE.GETTER;
    private mutations = MODULES.URL_CACHE_CHECKER.STORE.MUTATION;

    constructor(store: Store<unknown>) {
        super(
            MODULES.URL_CACHE_CHECKER.STORE.NAMESPACE.CACHED_URL_COLLECTION,
            store
        );
    }

    get items(): IUrlIndexStatusItem[] {
        return this.getter(this.getters.CACHE_URL_STATUS_COLLECTION);
    }

    push(items: IUrlIndexStatusItem[][]): void {
        this.commit(this.mutations.PUSH_CACHE_URL_STATUS_COLLECTION, items);
    }

    clearCollection(): void {
        this.commit(this.mutations.CLEAN_CACHE_URL_STATUS_COLLECTION);
    }
}
