











































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import UploadFile from '../components/UploadFile.vue';
import PreviewUrlsToCheck from '../components/PreviewUrlsToCheck.vue';
import UploadedUrlsProcessor from '../components/UploadedUrlsProcessor.vue';
import Results from '../components/Results.vue';
import DownloadCSV from '../components/DownloadCSV.vue';
import RequestQueueModule from '../helpers/request-queue-module.helper';
import CachedUrlCollectionModule from '../helpers/cached-url-collection-module.helper';
import UploadedFileModule from '../helpers/uploaded-file-module.helper';
import downloadCSV from '../handlers/download-csv.handler';

@Component({
    components: {
        UploadFile,
        PreviewUrlsToCheck,
        UploadedUrlsProcessor,
        DownloadCSV,
        Results,
    },
})
export default class UrlCacheView extends Vue {
    step = 1;
    snackbar = false;
    dialog = false;

    requestQueueStoreHelper: RequestQueueModule = new RequestQueueModule(
        this.$store
    );

    cachedUrlCollectionStoreHelper: CachedUrlCollectionModule = new CachedUrlCollectionModule(
        this.$store
    );

    uploadedFileStoreHelper: UploadedFileModule = new UploadedFileModule(
        this.$store
    );

    downloadCSV(e: Event): void {
        e.preventDefault();

        downloadCSV({
            urls: this.cachedUrlCollectionStoreHelper.items,
        });
    }

    get stepCompleted(): { [key: string]: boolean } {
        return {
            1: this.step > 1,
            2: this.step > 2,
        };
    }

    get isFileUploaded(): boolean {
        return this.uploadedFileStoreHelper.getFileIsReady;
    }

    get isProcessFinished(): boolean {
        return this.requestQueueStoreHelper.currentProgress <= -1;
    }

    mounted(): void {
        if (
            this.requestQueueStoreHelper.currentProgress > -1 ||
            this.cachedUrlCollectionStoreHelper.items.length > 0
        ) {
            this.step = 3;
        } else if (this.uploadedFileStoreHelper.getFileIsReady) {
            this.step = 2;
        }
    }

    stepUp(): void {
        this.step = this.step + 1;
    }

    stepDown(): void {
        this.step = this.step - 1;
    }

    reset(): void {
        this.uploadedFileStoreHelper.clearSelectedFile();
        this.requestQueueStoreHelper.clearQueue();
        this.cachedUrlCollectionStoreHelper.clearCollection();

        this.step = 1;
        this.dialog = false;
    }

    @Watch('isProcessFinished')
    onProcessFinished(newValue: boolean, oldValue: boolean): void {
        if (oldValue === false && newValue === true) {
            this.snackbar = true;
        }
    }
}
