import axios, { AxiosResponse } from 'axios';

export default class UrlCacheStatusService {
    constructor(private readonly config: IUrlIndexCheckerConfig) {}

    async checkUrls(urlListInBase64: string): Promise<IUrlIndexStatusItem[][]> {
        const data = {
            batchSize: this.config.batchSize.toString(),
            payload: urlListInBase64,
        };

        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': this.config.apiKey,
            },
        };

        if (process.env.NODE_ENV === 'development') {
            const p = new Promise((resolve: any) => {
                setTimeout(() => resolve(), 2000);
            });
            await p; // simulate api delay
            const urlList = atob(urlListInBase64).split('\n');

            return Promise.resolve([
                urlList.reduce((acc: any, curr: string) => {
                    const r = Math.floor(Math.random() * 1000000);
                    acc.push({
                        url: curr,
                        data: {
                            cachedDate:
                                'this is a mock response for development',
                            cachedDomainName:
                                r % 2 === 0
                                    ? 'foo' + r
                                    : r % 5 === 0
                                    ? ''
                                    : 'bar' + r,
                            query: 'q',
                            statusCode: r % 5 === 0 ? 404 : 200,
                        },
                    });

                    return acc;
                }, []),
            ]);
        }

        return axios
            .post(`${this.config.endpoint}`, data, axiosConfig)
            .then((r: AxiosResponse<any>) => {
                return r.data.body as IUrlIndexStatusItem[][];
            });
    }
}
