import { MODULES } from '@/constants';
import { Store } from 'vuex';
import StoreModuleHelper from '../../../lib/helpers/store-helper';

export default class UploadedFileModule extends StoreModuleHelper {
    constructor(store: Store<unknown>) {
        super(MODULES.URL_CACHE_CHECKER.STORE.NAMESPACE.UPLOADED_FILE, store);
    }

    get getFileIsReady(): boolean {
        return this.getter(
            MODULES.URL_CACHE_CHECKER.STORE.GETTER.FILE_IS_READY
        );
    }

    get getSelectedFile(): File | null {
        return this.getter(
            MODULES.URL_CACHE_CHECKER.STORE.GETTER.SELECTED_FILE
        );
    }

    get urlList(): string[] {
        return this.getter(MODULES.URL_CACHE_CHECKER.STORE.GETTER.URL_LIST);
    }

    async setSelectedFile(fileObject: File): Promise<void> {
        await this.dispatch(
            MODULES.URL_CACHE_CHECKER.STORE.ACTION.SET_SELECTED_FILE,
            fileObject
        );

        this.setFileAsReady(true);
    }

    clearSelectedFile(): void {
        this.setFileAsReady(false);
    }

    private setFileAsReady(fileStatus: boolean): void {
        this.commit(
            MODULES.URL_CACHE_CHECKER.STORE.MUTATION.SET_FILE_IS_READY,
            fileStatus
        );
    }
}
