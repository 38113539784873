









import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import config from '../config';
import proccessRequestsQueueHandler from '../handlers/process-requests-queue.handler';
import CachedUrlCollectionModule from '../helpers/cached-url-collection-module.helper';
import RequestQueueModule from '../helpers/request-queue-module.helper';
import UploadedFileModule from '../helpers/uploaded-file-module.helper';
import UrlCacheStatusService from '../services/url-index-status.service';

@Component
export default class CheckUrls extends Vue {
    uploadedFileStoreHelper: UploadedFileModule = new UploadedFileModule(
        this.$store
    );

    requestQueueStoreHelper: RequestQueueModule = new RequestQueueModule(
        this.$store
    );

    cachedUrlStoreHelper: CachedUrlCollectionModule = new CachedUrlCollectionModule(
        this.$store
    );

    onClick(): void {
        this.startProcessing();
    }

    startProcessing(isResumingProcess = false): void {
        proccessRequestsQueueHandler({
            numberOfConcurrentGroups: config.numberOfConcurrentGroups,
            storeHelpers: {
                requestQueue: this.requestQueueStoreHelper,
                cachedUrls: this.cachedUrlStoreHelper,
            },
            urlCacheStatusService: new UrlCacheStatusService(config),
            isResumingProcess,
        });

        this.$emit('processStarted');
    }

    mounted(): void {
        if (this.requestQueueStoreHelper.thereArePendingUrlsToProcess)
            this.startProcessing(true);
    }
}
