






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CachedUrlCollectionModule from '../helpers/cached-url-collection-module.helper';
import downloadCSV from '../handlers/download-csv.handler';

@Component
export default class DownloadCSVComponent extends Vue {
    cachedUrlCollectionStoreHelper: CachedUrlCollectionModule = new CachedUrlCollectionModule(
        this.$store
    );

    downloadCSV(): void {
        downloadCSV({
            urls: this.cachedUrlCollectionStoreHelper.items,
        });
    }
}
