




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import config from '../config';
import RequestQueueModule from '../helpers/request-queue-module.helper';
import UploadedFileModule from '../helpers/uploaded-file-module.helper';

@Component
export default class UploadFile extends Vue {
    uploadedFileStoreHelper: UploadedFileModule = new UploadedFileModule(
        this.$store
    );

    requestQueueStoreHelper: RequestQueueModule = new RequestQueueModule(
        this.$store
    );

    get urlList(): string[] {
        return this.uploadedFileStoreHelper.urlList;
    }

    async onUpload(file: File): Promise<void> {
        await this.uploadedFileStoreHelper.setSelectedFile(file);

        this.requestQueueStoreHelper.setRequestQueue({
            urlList: this.urlList,
            batchSize: config.batchSize,
        });
    }
}
