import { Store } from 'vuex';

export default abstract class StoreModuleHelper {
    constructor(
        private readonly namespace: string,
        private readonly store: Store<unknown>
    ) {}

    protected getter(property: string): any {
        return this.store.getters[`${this.namespace}/${property}`];
    }

    protected commit(mutation: string, payload: unknown = undefined): void {
        this.store.commit(`${this.namespace}/${mutation}`, payload);
    }

    protected async dispatch(action: string, payload: unknown): Promise<void> {
        await this.store.dispatch(`${this.namespace}/${action}`, payload);
    }
}
