import CachedUrlCollectionModule from '../helpers/cached-url-collection-module.helper';
import RequestQueueModule from '../helpers/request-queue-module.helper';
import UrlCacheStatusService from '../services/url-index-status.service';

const proccessRequestsQueueHandler = async (params: {
    numberOfConcurrentGroups: number;
    storeHelpers: {
        requestQueue: RequestQueueModule;
        cachedUrls: CachedUrlCollectionModule;
    };
    urlCacheStatusService: UrlCacheStatusService;
    isResumingProcess: boolean;
}): Promise<void> => {
    const urlGroups = params.storeHelpers.requestQueue.requestQueue;
    let currentIteration = 0;
    let requests: Promise<IUrlIndexStatusItem[][]>[] = [] as Promise<any>[];

    if (!params.isResumingProcess) {
        params.storeHelpers.requestQueue.setProgress(0);
        params.storeHelpers.cachedUrls.clearCollection();
    }

    for (const urlGroup of urlGroups) {
        const base64Urls = Buffer.from(urlGroup.join('\n')).toString('base64');

        if (urlGroup.length > 0) {
            requests.push(params.urlCacheStatusService.checkUrls(base64Urls));
            params.storeHelpers.requestQueue.cleanGroup(currentIteration); // FIXME TODO: fix this, when resuming after reload, the current group processed while reloading is lost
        }

        if (++currentIteration % params.numberOfConcurrentGroups !== 0)
            continue;

        const result = await Promise.all(requests);
        params.storeHelpers.cachedUrls.push(
            result.reduce((acc: any, curr: any) => {
                acc.push(...curr);

                return acc;
            }, [])
        );

        const progress = Math.floor(
            (currentIteration / urlGroups.length) * 100
        );
        params.storeHelpers.requestQueue.setProgress(progress);

        requests = [];
    }

    params.storeHelpers.requestQueue.clearQueue();
    params.storeHelpers.requestQueue.setProgressAsFinished();
};

export default proccessRequestsQueueHandler;
